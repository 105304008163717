<template>
	<div class="mystere-container">
		<p v-html="mystereDuJour.fruit" class="block-title"></p>
		<p v-html="mystereDuJour.evangile"></p>
	</div>
</template>

<script>
export default {
	name: "Mystere",
	props: {
		number: {
			type: Number,
			required: true,
			validator: (value) => value >= 1 && value <= 5
		}
	},
	data() {
		return {
			mysteres: {
				"joyeux": [
					{
						"titre": "1er Mystère Joyeux :<br />L'Annonciation de l'Ange Gabriel à la Vierge Marie",
						"fruit": "Fruit du mystère :<br />l’humilité.",
						"evangile": "«Le sixième mois, l'ange Gabriel fut envoyé par Dieu dans une ville de Galilée, appelée Nazareth, à une vierge, accordée en mariage à un homme de la maison de David, appelé Joseph; et le nom de la Vierge était Marie.» (Lc 1,26-27)"
					},
					{
						"titre": "2e Mystère Joyeux :<br />Visite de la Vierge Marie à sa cousine Élisabeth",
						"fruit": "Fruit du mystère :<br />la charité fraternelle.",
						"evangile": "«En ces jours-là, Marie se mit en route rapidement vers une ville de la montagne de Judée. Elle entra dans la maison de Zacharie et salua Élisabeth. Or, quand Élisabeth entendit la salutation de Marie, l'enfant tressaillit en elle. Alors, Élisabeth fut remplie de l'Esprit Saint, et s'écria d'une voix forte: 'Tu es bénie entre toutes les femmes, et le fruit de tes entrailles est béni!'» (Lc 1, 39-42)"
					},
					{
						"titre": "3e Mystère Joyeux :<br />La naissance de Jésus dans la grotte de Bethléem",
						"fruit": "Fruit du mystère :<br />l’esprit de pauvreté.",
						"evangile": "«En ces jours-là, parut un édit de l'empereur, ordonnant de recenser toute la terre - ce premier recensement eut lieu lorsque Quirinius était gouverneur de Syrie. Et chacun allait se faire inscrire dans sa ville d'origine. Joseph, lui aussi, quitta la ville de Nazareth en Galilée, pour monter en Judée, à la ville de David appelée Bethléem, car il était de la maison et de la descendance de David. Il venait se faire inscrire avec Marie, son épouse, qui était enceinte. Or, pendant qu'ils étaient là, arrivèrent les jours où elle devait enfanter. Et elle mit au monde son fils premier-né; elle l'emmaillota et le coucha dans une mangeoire, car il n'y avait pas de place pour eux dans la salle commune» (Lc 2,1-7)"
					},
					{
						"titre": "4e Mystère Joyeux :<br />Jésus est présenté au temple par Marie et Joseph",
						"fruit": "Fruit du mystère :<br />pureté et obéissance.",
						"evangile": "«Quand arriva le huitième jour, celui de la circoncision, l'enfant reçut le nom de Jésus, le nom que l'ange lui avait donné avant sa conception. Quand arriva le jour fixé par la loi de Moïse pour la purification, les parents de Jésus le portèrent à Jérusalem pour le présenter au Seigneur, selon ce qui est écrit dans la Loi: Tout premier-né de sexe masculin sera consacré au Seigneur» (Lc 2, 21-24)"
					},
					{
						"titre": "5e Mystère Joyeux :<br />Jésus retrouvé dans le temple",
						"fruit": "Fruit du mystère :<br />la recherche de Dieu en toutes choses.",
						"evangile": "«Chaque année, les parents de Jésus allaient à Jérusalem pour la fête de la Pâque. Quand il eut douze ans, ils firent le pèlerinage suivant la coutume. Comme ils s'en retournaient à la fin de la semaine, le jeune Jésus resta à Jérusalem sans que ses parents s'en aperçoivent. Pensant qu'il était avec leurs compagnons de route, ils firent une journée de chemin avant de le chercher parmi leurs parents et connaissances. Ne le trouvant pas, ils revinrent à Jérusalem en continuant à le chercher. C'est au bout de trois jours qu'ils le retrouvèrent dans le Temple, assis au milieu des docteurs de la Loi: il les écoutait et leur posait des questions, et tous ceux qui l'entendaient s'extasiaient sur son intelligence et sur ses réponses» (Lc 2, 41-47)"
					}
				],
				"douloureux": [
					{
						"titre": "1er Mystère Douloureux :<br />L'agonie de Jésus à Gethsémani",
						"fruit": "Fruit du mystère :<br />le regret de nos péchés (la contrition).",
						"evangile": "«Alors Jésus parvient avec eux à un domaine appelé Gethsémani et leur dit: 'Restez ici, pendant que je m'en vais là-bas pour prier'. Il emmena Pierre, ainsi que Jacques et Jean, les deux fils de Zébédée, et il commença à ressentir tristesse et angoisse. Il leur dit alors: 'Mon âme est triste à en mourir. Demeurez ici et veillez avec moi'. Il s'écarta un peu et tomba la face contre terre, en faisant cette prière: 'Mon Père, s'il est possible, que cette coupe passe loin de moi! Cependant, non pas comme je veux, mais comme tu veux'» (Mt 26, 36-39)."
					},
					{
						"titre": "2e Mystère Douloureux :<br />La flagellation de Jésus",
						"fruit": "Fruit du mystère :<br />la mortification de nos sens et de notre corps.",
						"evangile": "«Pilate leur relâcha Barabbas; et, après avoir fait battre de verges Jésus, il le livra pour être crucifié» (Mt 27, 26)."
					},
					{
						"titre": "3e Mystère Douloureux :<br />Le couronnement d'épines",
						"fruit": "Fruit du mystère :<br />la mortification de nos pensées et de notre esprit.",
						"evangile": "«Alors les soldats du gouverneur emmenèrent Jésus dans le prétoire et rassemblèrent autour de lui toute la garde. Ils lui enlevèrent ses vêtements et le couvrirent d'un manteau rouge. Puis, avec des épines, ils tressèrent une couronne, et la posèrent sur sa tête; ils lui mirent un roseau dans la main droite et, pour se moquer de lui, ils s'agenouillaient en lui disant: 'Salut, roi des Juifs!'» (Mt 27, 27-29)."
					},
					{
						"titre": "4e Mystère Douloureux :<br />Le portement de la Croix",
						"fruit": "Fruit du mystère :<br />la patience dans les épreuves.",
						"evangile": "«Ils réquisitionnent, pour porter la croix, un passant, Simon de Cyrène, le père d'Alexandre et de Rufus, qui revenait des champs. Et ils amènent Jésus à l'endroit appelé Golgotha, c'est-à-dire: Lieu-du-Crâne, ou Calvaire» (Mc 15, 21-22)."
					},
					{
						"titre": "5e Mystère Douloureux :<br />Jésus est crucifié et meurt sur la Croix",
						"fruit": "Fruit du mystère :<br />un amour plus grand pour Jésus, mort pour nous sauver.",
						"evangile": "«Lorsqu'on fut arrivé au lieu dit Le Crâne, ou Calvaire, on mit Jésus en croix, avec les deux malfaiteurs, l'un à droite et l'autre à gauche. Jésus disait: 'Père, pardonne-leur: ils ne savent pas ce qu'ils font'... Il était déjà presque midi; l'obscurité se fit dans tout le pays jusqu'à trois heures, car le soleil s'était caché. Le rideau du temple se déchira par le milieu. Alors, Jésus poussa un grand cri: 'Père, entre tes mains je remets mon esprit'» (Lc 23, 32-33, 44-46)."
					}
				],				
				"glorieux": [
					{
						"titre": "1er Mystère Glorieux :<br />La Résurrection de Jésus",
						"fruit": "Fruit du mystère : la foi.",
						"evangile": "«Le premier jour de la semaine, de grand matin, les femmes se rendirent au sépulcre, portant des aromates qu'elles avaient préparés. Elles trouvèrent la pierre roulée sur le côté du tombeau. Elles entrèrent, mais ne trouvèrent pas le corps du Seigneur Jésus. Elles ne savaient que penser, lorsque deux hommes se présentèrent à elles, avec un vêtement éblouissant. Saisies de crainte, elles baissaient le visage vers le sol. Ils leur dirent: 'Pourquoi cherchez-vous le Vivant parmi les morts? Il n'est pas ici, il est ressuscité'» (Lc 24, 1-6)."
					},
					{
						"titre": "2e Mystère Glorieux :<br />L'Ascension du Seigneur au ciel",
						"fruit": "Fruit du mystère :<br />l’espérance et le désir du Ciel.",
						"evangile": "«Après ces paroles, tandis que les Apôtres le regardaient, il s’éleva, et une nuée vint le soustraire à leurs yeux. Et comme ils fixaient encore le ciel où Jésus s’en allait, voici que, devant eux, se tenaient deux hommes en vêtements blancs, qui leur dirent : 'Galiléens, pourquoi restez-vous là à regarder vers le ciel ? Ce Jésus qui a été enlevé au ciel d’auprès de vous, viendra de la même manière que vous l’avez vu s’en aller vers le ciel.'» (Ac 1, 9-11)"
					},
					{
						"titre": "3e Mystère Glorieux :<br />La descente du Saint-Esprit au Cénacle",
						"fruit": "Fruit du mystère :<br />la descente du Saint-Esprit dans nos âmes.",
						"evangile": "«Quand arriva le jour de la Pentecôte, ils se trouvaient réunis tous ensemble. Soudain, il vint du ciel un bruit pareil à celui d'un violent coup de vent: toute la maison où ils se tenaient en fut remplie. Ils virent apparaître comme une sorte de feu qui se partageait en langues et qui se posa sur chacun d'eux. Alors ils furent tous remplis de l'Esprit Saint: ils se mirent à parler en d'autres langues, et chacun s'exprimait selon le don de l'Esprit» (Ac 2, 1-4)."
					},
					{
						"titre": "4e Mystère Glorieux :<br />L'Assomption de Marie au Ciel",
						"fruit": "Fruit du mystère :<br />la grâce d’une bonne mort.",
						"evangile": "«Désormais tous les âges me diront bienheureuse. Le Puissant fit pour moi des merveilles» (Lc 1, 48-49)."
					},
					{
						"titre": "5e Mystère Glorieux :<br />Marie est couronnée Reine du ciel et de la terre",
						"fruit": "Fruit du mystère :<br />une plus grande dévotion envers la Vierge Marie.",
						"evangile": "«Un signe grandiose apparut dans le ciel: une Femme, ayant le soleil pour manteau, la lune sous les pieds, et sur la tête une couronne de douze étoiles» (Ap 12, 1)."
					}
				],
				"lumineux": [
					{
						"titre": "1er Mystère Lumineux :<br />Le Baptême dans le Jourdain",
						"fruit": "Fruit du mystère :<br />l’état de grâce.",
						"evangile": "«Dès que Jésus fut baptisé, il sortit de l'eau; voici que les cieux s'ouvrirent, et il vit l'Esprit de Dieu descendre comme une colombe et venir sur lui. Et des cieux, une voix disait: 'Celui-ci est mon Fils bien-aimé; en lui j'ai mis tout mon amour'». (Mt 3,16-17)"
					},
					{
						"titre": "2e Mystère Lumineux :<br />Les noces de Cana",
						"fruit": "Fruit du mystère :<br />confiance en la volonté de Dieu.",
						"evangile": "«Trois jours plus tard, il y avait un mariage à Cana en Galilée. La mère de Jésus était là. Jésus aussi avait été invité au repas de noces avec ses disciples. Or, on manqua de vin; la mère de Jésus lui dit: 'Ils n'ont plus de vin'. Jésus répondit: 'Femme, que me veux-tu? Mon heure n'est pas encore venue'. Sa mère dit aux serviteurs: 'Faites tout ce qu'il vous dira'». (Jn 2, 1-5)"
					},
					{
						"titre": "3e Mystère Lumineux :<br />L'annonce du Royaume de Dieu et l'invitation à la conversion",
						"fruit": "Fruit du mystère :<br />la conversion intérieure, la sainteté.",
						"evangile": "\"Les temps sont accomplis: le règne de Dieu est tout proche. Convertissez-vous et croyez à la Bonne Nouvelle\". (Mc 1, 15)"
					},
					{
						"titre": "4e Mystère Lumineux :<br />La transfiguration",
						"fruit": "Fruit du mystère :<br />la contemplation.",
						"evangile": "«Six jours après, Jésus prend avec lui Pierre, Jacques et Jean son frère, et il les emmène à l'écart, sur une haute montagne. Il fut transfiguré devant eux; son visage devint brillant comme le soleil, et ses vêtements, blancs comme la lumière» (Mt 17, 1-2)."
					},
					{
						"titre": "5e Mystère Lumineux :<br />L'institution de l'Eucharistie",
						"fruit": "Fruit du mystère :<br />la pratique des sacrements.",
						"evangile": "«Pendant le repas, Jésus prit du pain, prononça la bénédiction, le rompit et le donna à ses disciples, en disant: 'Prenez, mangez: ceci est mon corps'» (Mt 26, 26)."
					}
				]
			}
		};
	},
	mounted() {
		this.$emit("updateTitre", this.mystereDuJour.titre); // Émettre l"événement lors du montage
	},
	watch: {
		'mystereDuJour.titre'(newVal) {
			this.$emit("updateTitre", newVal); // Émettre l'événement à chaque changement de titre
		}
	},
	computed: {
		getDay() {
			return new Date().getDay(); // 0 pour Dimanche, 1 pour Lundi, etc.
		},
		mystereDuJour() {
			switch (this.getDay) {
				case 0: // Dimanche
				case 3: // Mercredi
					return this.mysteres.glorieux[this.number];
				case 1: // Lundi
				case 6: // Samedi
					return this.mysteres.joyeux[this.number];
				case 2: // Mardi
				case 5: // Vendredi
					return this.mysteres.douloureux[this.number];
				case 4: // Jeudi
					return this.mysteres.lumineux[this.number];
				default:
					return ''; // Gérer un cas inattendu
			}
		}
	}
};
</script>

<style type="text/css">
.container {
	position: relative;
	height: 100vh; /* Hauteur totale de la fenêtre de visualisation, ajustez selon vos besoins */
}

.block-title {
	/*width: %;*/
	color: white;
	background-color: black;
	border-radius: 10px;
	padding: 20px;
}

.text-block {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	/* Ajoutez left: 0%;, left: 33%;, et left: 66%; respectivement si vous voulez les espacer horizontalement */
}

.mystere-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
