<template>
	<div class="medit-container">
		<p>« {{meditation.content}} »</p>
		<p v-html="meditation.author" style="font-style: italic;"></p>
	</div>
</template>

<script>
export default {
	name: "Meditation",
	props: {
		number: {
			type: Number,
			required: true,
			validator: (value) => value >= 1 && value <= 5
		}
	},
	data() {
		return {
			meditations: {
				"lumineux": [
					{
						"content": "Un Chrétien créé à l’Image de Dieu, un Chrétien racheté par le Sang d’un Dieu. Un Chrétien, l’enfant de Dieu, le frère d’un Dieu, l’héritier d’un Dieu",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Tout ce que le Fils demande au Père Lui est accordé. Tout ce que la Mère demande au Fils Lui est pareillement accordé… Ce qui doit nous engager à nous adresser à Elle avec une grande confiance, c’est qu’Elle est toujours attentive",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Notre-Seigneur qui est la Vérité même, ne fait pas moins de cas de sa Parole que de son Corps… Il est tout à fait impossible d’aimer Dieu et de Lui plaire sans être nourri de cette Parole Divine",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Comme les disciples sur le Thabor ne virent plus que Jésus seul, les âmes intérieures, sur le Thabor de leur cœur, ne voient que Notre-Seigneur. Ce sont deux amis qui ne se lassent jamais l’un de l’autre",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Toutes les bonnes œuvres réunies n’équivalent pas au Sacrifice de la Messe, parce qu’elles sont les œuvres des hommes et la Sainte Messe est l’Œuvre de Dieu",
						"author": "Saint Curé d’Ars"
					}
				],
				"joyeux": [
					{
						"content": "Nous ne sommes aux Yeux de Dieu que ce que nous sommes : ni plus, ni moins. Nous ne devons nous occuper qu’à Lui être agréables. Tout notre mérite est de coopérer à la Grâce",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Un bon Chrétien qui aime Dieu et le prochain, voyez comme il est heureux ! Quelle paix dans son âme ! Que ceux qui aiment le Bon Dieu sont heureux et aussi ceux qui sont autour d’eux",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Plus on se rend pauvre pour l’Amour de Dieu, plus on est riche en réalité… Les pauvres et les amis des pauvres sont les Amis de Dieu",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Mes frères, ne sommes-nous pas bien plus heureux que Syméon ? Nous pouvons garder Jésus toujours, si nous voulons. Il ne vient pas seulement dans nos bras, mais dans notre cœur",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Si nous voulons témoigner au Bon Dieu que nous L’aimons, il faut accomplir sa Sainte Volonté. Le moyen le plus sûr de connaître la Volonté de Dieu, c’est de prier notre Bonne Mère",
						"author": "Saint Curé d’Ars"
					}
				],
				"douloureux": [
					{
						"content": "Un Chrétien doit être toujours prêt au combat. C’est dans le combat que nous prouvons à Dieu notre amour et dans l’acceptation des peines qu’Il nous envoie",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Le Bon Dieu ne demande pas de nous le martyre du corps, Il nous demande seulement le martyre du cœur et de la volonté",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Ceux qui nous humilient sont nos amis, et non ceux qui nous louent…",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Notre Seigneur est notre Modèle : prenons notre croix et suivons-Le. Si vous craignez que le courage vous manque, portez vos regards sur la Croix où Jésus-Christ est mort, et vous verrez que le courage ne vous manquera pas",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Ô Amour immense d’un Dieu pour Sa créature ! Il nous attend les bras ouverts, Il nous ouvre la Plaie de son Divin Cœur",
						"author": "Saint Curé d’Ars"
					}
				],
				"glorieux": [
					{
						"content": "La Foi peut tout. Mon Dieu, donnez-nous la Foi et nous Vous aimerons de tout cœur",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Voyez, mes enfants, il faut réfléchir que nous avons une âme à sauver et une éternité qui nous attend",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Le Saint-Esprit est comme un jardinier qui travaille notre âme… Nous n’avons qu’à dire « Oui » et à nous laisser conduire",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Au Ciel, notre cœur sera tellement perdu, noyé dans le bonheur d’aimer Dieu, que nous ne nous occuperons ni de nous, ni des autres, mais de Dieu seul",
						"author": "Saint Curé d’Ars"
					},
					{
						"content": "Le Cœur de cette Bonne Mère n’est qu’Amour et Miséricorde ; Elle ne désire que de nous voir heureux. Il suffit seulement de se tourner vers Elle pour être exaucé…",
						"author": "Saint Curé d’Ars"
					}
				]
			}
		};
	},
	computed: {
		getDay() {
			return new Date().getDay(); // 0 pour Dimanche, 1 pour Lundi, etc.
		},
		meditation() {
			switch (this.getDay) {
				case 0: // Dimanche
				case 3: // Mercredi
					return this.meditations.glorieux[this.number];
				case 1: // Lundi
				case 6: // Samedi
					return this.meditations.joyeux[this.number];
				case 2: // Mardi
				case 5: // Vendredi
					return this.meditations.douloureux[this.number];
				case 4: // Jeudi
					return this.meditations.lumineux[this.number];
				default:
					return ''; // Gérer un cas inattendu
			}
		}
	}
};
</script>

<style type="text/css">
.medit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
