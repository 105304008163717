<template>
	<div class="app-container">
		<header>
			<div class="header-top">
				<!-- <img src="https://cdn.shopify.com/s/files/1/0064/1564/6787/files/Joyeux-1-Annonciation_compact.png" alt="Logo" class="header-logo" /> -->
				<p style="color:#000" v-if="!mystereTitle">Progression de ton chapelet</p>
				<p style="color:#000;font-weight: 700;" v-if="mystereTitle" v-html="mystereTitle"></p>
			</div>
			<div class="progress-container">
				<div class="progress-bar" :style="{ width: progress + '%' }"></div>
			</div>
		</header>
		<main class="main-content">
				<transition
						:enter-active-class="swipeDirection === 'right' ? 'animate__animated animate__slideInRight' : 'animate__animated animate__slideOutRight'"
						:leave-active-class="swipeDirection === 'right' ? 'animate__animated animate__slideOutLeft' : 'animate__animated animate__slideInLeft'"
						mode="out-in"
				>
					<!-- <div v-bind:class="{'pray': !displayMystere()}" v-if="showText"> -->
					<div class="pray" v-if="showText">
						<Mystere :number="this.block" @updateTitre="handleUpdateTitre" v-if="displayMystere()" />
						<Meditation :number="this.block" v-if="displayMeditation()" />
						<NotrePere v-if="displayNotrePere()" />
						<JVSM v-if="displayJVSM()" />
						<GloireAuPere v-if="displayGloireAuPere()" />
						<Fatima v-if="displayFatima()" />
					</div>
				</transition>
		</main>
		<footer>
				<!-- <div v-if="step === 1 && block === 0">
						<img id="swipe-tuto" src="https://cdn2.iconfinder.com/data/icons/hand-gestures-31/256/Artboard_6-512.png">
						<p>swipe</p>   
				</div>
 -->
				<div class="progress-indicators">
					<div
						v-for="n in 15"
						:key="n"
						class="tick"
						:class="{ active: n === step }"
						@click="goToStep(n)"
					></div>
				</div>
				<!-- <button class="next-button" @click="next()">Suivant</button> -->
		</footer>
	</div>
</template>

<script>
import 'animate.css/animate.min.css';
import NotrePere from "@/components/NotrePere";
import Mystere from "@/components/Mystere";
import Meditation from "@/components/Meditation";
import JVSM from "@/components/JVSM";
import GloireAuPere from "@/components/GloireAuPere";
import Fatima from "@/components/Fatima";

export default {
		name: "Home",

		components: {
				NotrePere,
				Mystere,
				Meditation,
				JVSM,
				GloireAuPere,
				Fatima
		},

		data() {
				return {
					block: 0,
					step: 1,
					progress: 0,
					touchstartX: 0,
					touchendX: 0,
					lastSwipeTime: 0,
					showText: true,
					swipeDirection: 'right',
					mystereTitle: null
				};
		},
		computed: {
				headerBackgroundColor() {
						// Supposons que vous ayez un ensemble de couleurs prédéfinies pour chaque 'block'
						const colors = ['#5c6bc0', '#ff9800', '#4caf50', '#f44336', '#3f51b5'];
						// Utilisez le module (%) pour boucler sur les couleurs si le nombre de blocks dépasse les couleurs définies
						return colors[this.block % colors.length];
				}
		},
		mounted() {
				this.loadQueryParams();
				this.updateProgress();

				document.addEventListener('touchstart', e => {
						this.touchstartX = e.changedTouches[0].screenX;
				}, false);

				document.addEventListener('touchend', e => {
						this.touchendX = e.changedTouches[0].screenX;
						this.checkSwipeDirection();
				}, false);
		},

		watch: {
			'$route'() {
				this.loadQueryParams();
				this.updateProgress(); // Mettez à jour la barre de progression lorsque la route change
			}
		},

		methods: {
			handleUpdateTitre(newTitre) {
				this.mystereTitle = newTitre;
			},
			fadeText() {
					this.showText = false;
					setTimeout(() => {
						this.showText = true;
					}, 300);
			},

			loadQueryParams() {
					if (this.$route.query.b)
							this.block = parseInt(this.$route.query.b, 10) || 0;

					if (this.$route.query.s)
							this.step = parseInt(this.$route.query.s, 10) || 0;
			},

			checkSwipeDirection() {
					const currentTime = new Date().getTime();
					// Swipe vers la gauche (décrémenter)
					if (this.touchstartX < this.touchendX && currentTime - this.lastSwipeTime > 500) {
						this.lastSwipeTime = currentTime;
						this.prev();
					}
					// Swipe vers la droite (incrémenter)
					else if (this.touchstartX > this.touchendX && currentTime - this.lastSwipeTime > 500) {
						this.lastSwipeTime = currentTime;
						this.next();
					}
			},

			displayMystere() {
					if (this.step === 1)
							return true;

					return false
			},

			displayMeditation() {
					if (this.step === 2)
							return true;

					return false
			},

			displayNotrePere() {
					if (this.step === 3)
							return true;

					return false
			},

			displayJVSM() {
					if (this.step > 3 && this.step < 14)
							return true;

					return false
			},

			displayGloireAuPere() {
					if (this.step === 14)
							return true;

					return false
			},

			displayFatima() {
					if (this.step === 15)
							return true;

					return false
			},

			updateProgress() {
					// Calcul de la progression totale pour les 5 blocs (chaque bloc a 15 étapes)
					const totalSteps = 5 * 15; // 5 blocs de 15 étapes chacun
					const stepsCompleted = this.block * 15 + this.step; // Nombre total d'étapes complétées
					this.progress = (stepsCompleted / totalSteps) * 100; // Calcul de la progression en pourcentage
			},

			goToStep(stepNumber) {
					console.log('move to', stepNumber)
					this.step = stepNumber;
					this.updateProgress();
					this.fadeText();
			},

			prev() {
					this.swipeDirection = 'left';

					if (this.step > 1) {
						this.step--;
					} else if (this.block > 0) {
						this.block--;
						this.step = 15; // Ou le nombre total d'étapes dans le bloc précédent si ce n'est pas 15
					}

					this.updateProgress(); // Mettre à jour la progression

					// Mettre à jour la route pour refléter le nouveau block et step
					this.$router.push({ name: "pray", query: { b: this.block.toString(), s: this.step.toString() } });
			},

			next() {
					this.swipeDirection = 'right';
					if (this.step === 15) {
							this.block++;
							this.step = 1;
							this.progress = 0; // Réinitialiser la barre de progression
					
							if (this.block > 4)
									return this.$router.push({ name: "finished" });
					}
					else {
							this.step++;
							this.progress = (this.step / 15) * 100;
					}

					this.updateProgress();
					this.fadeText();

					this.$router.push({ name: "pray", query: { b: this.block.toString(), s: this.step.toString() } });
			}
		}
};
</script>

<style type="text/css">

@keyframes rotate-image {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(30deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

#swipe-tuto {
		width: 60px;
		display: block;
		margin: 0 auto;
		animation: rotate-image 2s infinite linear;
}

.progress-indicators {
	padding-top: 20px;
	display: flex;
	justify-content: center;
}

.tick {
	width: 10px; /* Taille des indicateurs */
	height: 10px;
	border-radius: 50%; /* Les rend circulaires */
	background-color: #ccc; /* Couleur par défaut */
	margin: 0 5px; /* Espacement entre les indicateurs */
}

.tick.active {
	background-color: #000; /* Couleur de l'indicateur actif */
}

html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Inter;
	overflow: hidden;
}

/* Stylisez le conteneur de l'application pour qu'il occupe toute la hauteur et soit flex */
.app-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh; /* Utilisez min-height pour occuper au moins toute la hauteur de la fenêtre */
	position: relative;
}

header {
	/*background-color: rgba(51, 0, 255, 0.61); /* Couleur de l'en-tête */
	color: #FFF;
	text-align: center; /* Centre le texte du titre */
	padding: 0px 20px; /* Ajustez selon vos besoins */
}

.header-top {
	display: flex;
	align-items: center; /* Alignement vertical des éléments */
	justify-content: center; /* Centrage horizontal */
	gap: 20px; /* Espace entre le logo et le titre */
}

.header-logo {
	width: 80px; /* Ou la taille désirée pour le logo */
}

.progress-container {
		margin-bottom: 5px;
	width: 100%;
	background-color: #ccc;
	border-radius: 10px;
}

.progress-bar {
	height: 10px;
	background-color: #000;
	width: 0;
	border-radius: 10px;
	transition: width 0.5s ease;
}

.main-content {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 160px); /* Hauteur de la vue moins la hauteur de l'en-tête et du pied de page */
	margin: 0 20px;
	overflow: auto; /* Ajoutez un défilement si le contenu dépasse la hauteur de la fenêtre */
	-webkit-overflow-scrolling: touch;
}

.pray {
	flex: 1; /* Cela permet à .pray de remplir tout l'espace disponible */
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	text-align: center;
}

footer {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px 20px;
	text-align: center;
	z-index: 1000; /* S'assure que le pied de page reste au-dessus du contenu défilant */
}

/* Stylisez le bouton pour qu'il soit large et centré */
.next-button {
	width: calc(100% - 40px); /* Ajustez cette valeur pour augmenter l'espace sur les côtés */
	margin: auto; /* Ceci centre le bouton dans le footer */
	padding: 20px;
	font-size: 16px;
	color: white;
	background-color: #000; /* Bouton noir */
	border: none;
	cursor: pointer;
	border-radius: 17px;
}

/* Ajouter des styles pour les états hover et focus du bouton */
.next-button:hover,
.next-button:focus {
	background-color: #444; /* Assombrir le bouton au survol */
}

/* Style pour garantir que le contenu est bien centré et responsive */
@media (max-width: 600px) {
	.next-button {
		width: calc(100% - 20px); /* Espaces latéraux plus petits pour les appareils mobiles */
	}
}

/* Style pour garantir que le contenu est bien centré et responsive */
@media (max-width: 600px) {
	.content-header, .main-content {
		padding: 10px;
	}

	footer {
		padding: 10px 0; /* Padding plus petit pour les appareils mobiles */
		margin: 15px;
	}

	.next-button {
		width: 100%; /* Le bouton prend toute la largeur */
	}
}

.animate__animated {
	/* Réduisez la durée à, par exemple, 0.3 secondes */
	animation-duration: 0.3s !important;
}
</style>